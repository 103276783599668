


.nav-drawer {
  visibility: hidden;
  padding-bottom: 4em;
}

.nav-drawer__top-card-media__header {
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}

.nav-drawer__close-publication-button {
  align-self: flex-start;
}

.nav-drawer__close-drawer-button {
  align-self: flex-end;
}

.colibrio-reader__search-panel {
  padding-top: 40px;
  padding-bottom: 16px;
}

.colibrio-publication-nav {
  .list__group__header__prepend-icon {
    flex-shrink: 0;
    align-self: flex-start;
    padding-top: 8px;
  }
}


.colibrio-publication-nav-item-tile {
  flex-shrink: 1 !important;
  flex-grow: 1 !important;

  .list__tile {
    height: auto !important;
    min-height: 40px;
    padding-top: 11px;
    padding-bottom: 9px;
  }

  .list__tile__sub-title {
    color: rgba(0, 0, 0, 0.8);
  }
}

.colibrio-publication-nav__annotation-list {
  .v-list__group__items {
    padding-bottom: 0.5em;

    .colibrio-publication-nav-item-tile {
      .v-list__tile__content,
      .v-list__tile__sub-title {
        color: #000;
      }

      margin: 0.5em;
      padding: 0.5em;
      border-radius: 5px;

      a {
        padding-left: 16px;
      }
    }
  }
}

.v-list__tile__sub-title, .v-list__tile__title {
  white-space: normal !important;
}

.colibrio-publication-nav__item-tile--active {
  border-bottom: 1px solid #a63590;
}

.application .theme--light.list .list__tile__sub-title, .theme--light .list .list__tile__sub-title {
  color: rgba(0, 0, 0, 0.8);
}

.list__group:after, .list__group:before {
  background-color: transparent !important;
}

.list__group__header__prepend-icon.primary--text {
  color: rgba(0, 0, 0, 0.5) !important;
}

.nav-drawer__publication-title {
  color: white;
  padding: 8px;
}

.theme--dark .nav-drawer__top-card-media {
  background-color: #333 !important;
  border-color: #333 !important;
}

.nav-drawer__top-card-media__content {

}

.nav-drawer__top-card-media__title {
  padding: 8px;
}

.v-card__title {
  font-size: 1.2em;
  font-weight: bold;
}

ul[role=tree] {
  padding-top: 1em;
  font-size: 1.1em;
}

ul[role=tree] a {
  color: #333333;
}

.theme--dark ul[role=tree] {
  a {
    color: #eeeeee;
  }
}

ul[role=tree] > li[role=treeitem] {
  font-weight: bold;
  font-size: 1.1em;
}

ul[role=group] {
  padding-left: 1.1em;
  padding-top: 1em;
}

li[role=treeitem] {
  min-height: 40px;
  margin-top: 1em;
  margin-bottom: 1em;
  list-style-type: none;
  cursor: pointer;
}

ul[role=group] > li[role=treeitem] {
  font-weight: normal;
  list-style-type: inherit;
}

