
.colibrio-reader__search-panel {
  padding-left: 24px;
}

.colibrio-reader__search-panel__result-set {
  padding-left: 0;
}

.colibrio-reader__search-panel__result-item {
  min-height: 40px;
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  list-style-type: none;
  font-size: 1.2em;
}

.colibrio-reader__search-panel__clear-button {
  cursor: pointer;
}

.colibrio-publication-search-panel-query-term {
  background-color: rgba(255, 247, 12, 0.5);
}

.v-divider {
  margin-top: 40px !important;
}

