body {
  background-color: #fff !important;
}

.nav-tabs {
  display: none;
}

.bookReaderMenu {
  display: flex;
  justify-content: flex-end;
}

.nav-tabs {
  display: flex;
  flex-flow: column nowrap;
  transition: all 300ms;
}
.menu-tab-container {
  display: flex;
  transition: all 300ms;
}
.menu-tab-container.hide {
  transform: translateX(calc(100% - 3px));
  position: absolute;
}
.nav-tabs {
  border-bottom: none;
  display: flex;
  z-index: 1;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
/* width */
.nav-tabs::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.nav-tabs::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.nav-tabs::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.nav-tabs::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.nav-item {
  background-color: #000;
  font-family: Sofia Pro Regular;
  margin-right: 0px;
  border-radius: 0px;
}
.nav-tabs .nav-item {
  margin-bottom: 0px;
  border-radius: 0px;
}
.nav-tabs .nav-link {
  color: #fff;
  transition: border-color 0.125s ease-in;
  border: 1px solid #404040;
  white-space: nowrap;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
}
.nav-tabs .nav-link div {
  margin-top: 5px;
}
.nav-tabs .nav-link:hover {
  background-color: #d75828;
  border-color: #d75828;
}
.nav-tabs .nav-link.active {
  border-bottom-color: #404040;
  border-right-color: #404040;
  margin-right: -1px;
}
.nav-tabs .nav-link.active:hover {
  background-color: #d75828;
  border-color: #d75828;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #d75828;
  border-color: #d75828;
}
.card {
  border: none;
}

.card .card-header {
  display: none;
}

.card .collapse {
  display: block;
}

.mobile-tab-toggler {
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  background-color: #000;
  width: 3px;
  position: relative;
}

.mobile-tab-toggler button {
  position: absolute;
}

.reader_menu_toggle_button {
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
  padding: 0px !important;
}

.tab-content {
  width: 100%;
  height: 100vh;
}

#pannel-bookmarks {
  width: 100%;
  height: 100vh;
  position: relative;
  background: rgba(0, 0, 0, 50%);
}
#collapse-bookmarks {
  width: 400px;
  max-height: 100vh;
  min-height: 100vh;
  position: absolute;
  right: 0px;
  padding: 20px;
  background: #d75828;
}

#collapse-bookmarks .card-body, #collapse-timeline .card-body  {
  max-height: calc(100vh - 160px);
  min-height: calc(100vh - 160px);
  overflow-y: scroll;
  padding-left: 5px;
}
/* Chapter styling goes here */
#chapter-brief p {
  font-size: 20px;
}
.close-brief:hover {
  cursor: pointer;
}
#chapter-brief {
  display: none;
}
#chapter-brief.show {
  display: block;
  transition: transform;
}
#pannel-chapters,
#pannel-timeline {
  width: 100%;
  height: 100vh;
  position: relative;
  background: rgba(0, 0, 0, 50%);
}
#collapse-chapters {
  width: 500px;
  max-height: 100vh;
  min-height: 100vh;
  position: absolute;
  right: 0;
  padding: 20px;
  background: #d75828;
}

#chapter-brief {
  width: 400px;
  max-height: 100vh;
  min-height: 100vh;
  position: absolute;
  right: 400px;
  padding: 30px;
  background: white;
}
#collapse-chapters .card-body {
  max-height: calc(100vh - 160px);
  min-height: calc(100vh - 160px);
  overflow-y: scroll;
  padding-left: 0px;
}
#collapse-chapters .card-body ul li > h5:hover {
  text-decoration: underline;
  color: white;
  text-underline-offset: 2px;
  cursor: pointer;
}
#collapse-chapters .card-body ul li {
  border-left: 7px solid transparent;
}
#collapse-chapters .card-body ul li.active {
  border-left: 7px solid white;
}

.round {
  position: relative;
}

.round label {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  width: 22px;
  left: 0;
  position: absolute;
  top: 0;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: transparent;
  border-color: white;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/* Chapters header end here */

/* Timeline brief panel */
#collapse-timeline {
  width: 500px;
  max-height: 100vh;
  min-height: 100vh;
  position: absolute;
  right: 0;
  padding: 20px;
  background: #d75828;
}
#timeline-brief {
  width: 800px;
  max-height: 100vh;
  min-height: 100vh;
  position: absolute;
  right: 500px;
  padding: 30px;
  background: white;
}
#timeline-brief {
  display: none;
}
#timeline-brief.show {
  display: block;
  transition: transform;
}
/* Timeline brief panel style ends here */
/* Settings panel goes here */
#collapse-setting {
  min-height: 55vh;
  color: white;
  position: absolute;
  right: 0px;
  width: 400px;
  bottom: -0vh;
  padding: 30px 40px 10px;
  background: #d75828;
}

#brightness-range {
  width: 100%;
  -webkit-appearance: none;
  //background: white;
  height: 2px;
  outline: none;
  cursor: pointer;
}
#brightness-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  background: white;
}
/* Setting panel styling ends here */
.add-bookmark-btn {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  outline: none !important;
}

.timeline-details-section {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 85vh;
  font-family: 'Sofia Pro Light' !important;
}
/* width */
#collapse-bookmarks .card-body::-webkit-scrollbar,
#collapse-chapters .card-body::-webkit-scrollbar,
#collapse-timeline .card-body::-webkit-scrollbar,
.timeline-details-section::-webkit-scrollbar {
  width: 3px;
}

/* Track */
#collapse-bookmarks .card-body::-webkit-scrollbar-track,
#collapse-chapters .card-body::-webkit-scrollbar-track,
#collapse-timeline .card-body::-webkit-scrollbar-track,
.timeline-details-section::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 20%);
}

/* Handle */
#collapse-bookmarks .card-body::-webkit-scrollbar-thumb,
#collapse-chapters .card-body::-webkit-scrollbar-thumb,
#collapse-timeline .card-body::-webkit-scrollbar-thumb {
  background: #fff;
}

/* Handle on hover */
#collapse-bookmarks .card-body::-webkit-scrollbar-thumb:hover,
#collapse-chapters .card-body::-webkit-scrollbar-thumb:hover,
#collapse-timeline .card-body::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.timeline-details-section::-webkit-scrollbar-thumb {
  background: #d75828;
}

#collapse-bookmarks ul {
  list-style: none;
  padding-inline-start: 0px;
}

#collapse-bookmarks li {
  margin-bottom: 40px;
}

.bookmark-page-no {
  font-family: Sofia Pro light;
  color: #fff;
  opacity: 0.7;
  font-size: 18px;
  margin-bottom: 22px;
}

.bookmark-chapter-no {
  font-family: Sofia Pro Semi Bold;
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
}

.bookmark-chapter-name,
.chapter-brief-title,
.settings-title {
  font-family: Social Gothic Demibold;
  color: #fff;
  font-size: 30px;
  margin-bottom: 7px;
}

.bookmark-time {
  font-family: Sofia Pro Semi Bold;
  font-size: 18px;
  color: #fff;
}


@media (max-width: 1450px) {
  #timeline-brief {
    width: 650px !important;
  }
}

@media (max-width: 1300px) {
  #collapse-timeline {
    width: 470px !important;
  }
  #timeline-brief {
    width: 560px !important;
    right: 470px;
  }
}

@media (max-width: 1200px) {
  #collapse-timeline {
    width: 430px !important;
  }
  #timeline-brief {
    width: 480px !important;
    right: 430px;
  }
}

@media (max-width: 1100px) {
  .nav-tabs .nav-link {
    padding: 18px 5px;
  }
  #collapse-timeline {
    width: 380px !important;
  }
  #timeline-brief {
    width: 430px !important;
    right: 380px;
  }
}

@media screen and (max-width: 767px) {
  #collapse-bookmarks {
    width: 90%;
    padding: 20px 30px 10px;
  }

  #collapse-setting {
    min-height: 70vh !important;
  }
}

@media (max-width: 500px) {
  #collapse-setting {
    height: 70vh !important;
  }

}
@media (max-width: 600px) {
  #chapter-brief.show {
    display: block;
    z-index: 1030;
    position: absolute;
    left: 0;
    width: 100vw;
    transition: 0.3s;
  }
  #timeline-brief.show {
    display: block;
    z-index: 1030;
    position: absolute;
    left: 0;
    width: 100vw;
    transition: 0.3s;
  }
  #collapse-setting {
    width: 100vw;
  }
  #collapse-timeline {
    width: 100vw;
  }
}
.colibrio-publication-view__nav {
  outline: none !important;
}

.timeline-details-section, #collapse-timeline {
  line-height: 123% !important;
}
