

#chartdiv {
  width: 100%;
  height: 380px;
  background-color: #d75828;
}

.custom-reader {
  .col-auto {
    padding: 0px !important;
  }
}

#settings-sidebar {
  width: 400px !important;
}

#settings-left-sidebar {
  width: 40% !important;
}

.v-navigation-drawer {
  visibility: hidden;
}

.v-navigation-drawer.v-navigation-drawer--open {
  visibility: visible;
}

.colibrio-reader__publication-view {
  width: 100%;
  height: 100%;
}

.colibrio-reader__content--dark {
  background-color: #000;
}

@media (min-width: 700px) {
  .v-navigation-drawer--open {
    width: 40vw !important;
  }

  .colibrio-app-settings-drawer.v-navigation-drawer--open {
    width: 30vw !important;
  }
}

@media (min-width: 1000px) {
  .v-navigation-drawer--open {
    width: 35vw !important;
  }

  .colibrio-app-settings-drawer.v-navigation-drawer--open {
    width: 20vw !important;
  }
}

@media screen and (max-width: 750px) {
  #collapse-setting {
    min-height: 70vh !important;
  }
}

.bookReaderMenu {
  position: absolute;
  right: 0;
  width: 20%;
  z-index: 999;
}
/*.d-flex>* {
  flex: none !important;
}*/
