
.colibrio-image-zoom {
    position: relative;
    background: rgba(0,0,0,0.8);
    overflow: hidden;
}

.colibrio-image-zoom__image {
    position: absolute;
    z-index: 1;
    will-change: transform;
    overflow: hidden;
}

.application .theme--dark.toolbar, .theme--dark .toolbar {
    background-color: rgba(0,0,0,0.8) !important;
    border-color: rgba(0,0,0,0.8) !important;
}

