
.colibrio-settings-drawer {

  padding: 16px;
  visibility: hidden;

  /*label {
    padding: 16px;
    display: block;
    font-size: 1.2em;
    font-weight: 600;
  }*/

  /*input[type=checkbox] {
    appearance: checkbox;
    min-height: 40px;
    margin: 1em;
    width: 100%;
  }*/

  /*select {
    appearance: listbox;
    min-height: 40px;
    margin: 1em;
    width: 100%;
    margin-left: 0 !important;
    font-size: 1.2em;
  }

  input {
    margin-left: 0 !important;
    font-size: 1.2em;
  }*/
}

.colibrio-publication-settings__reflow-options {
  height: 60vh;
  width: 100%;

  .CodeMirror {
    height: 100%;
  }
}

.colibrio-publication-settings__custom-css-options {
  height: 60vh;
  width: 100%;

  .CodeMirror {
    height: 100%;
  }
}

.colibrio-publication-settings__card-text {
  padding-top: 0;
  padding-bottom: 0;
}
