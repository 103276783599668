

.colibrio-file-chooser__logo {
    max-height: 35vh;
    min-height: 10px;
    width: 100%;
    flex-shrink: 1;
    flex-grow: 1;
    background-image: url("../../../assets/images/colibrio-reader.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 16px;
    margin-top: -10%;
    cursor: pointer;
}

.theme--dark .colibrio-file-chooser__logo {
    background-image: url("../../../assets/images/colibrio-reader-darkmode.svg");
}

hr.divider {
    max-height: 32px;
    background-color: transparent !important;
    border: 0;
}

.colibrio-file-chooser__file-input {
    display: none;
}

.colibrio-file-chooser__button-panel {
    display: flex;
    justify-content: center;
    align-items: center;
}

.v-divider {
    margin-top: 2em !important;
}

.colibrio-file-chooser__url-form {
    width: 100%;
    max-width: 500px;
}

.colibrio-file-chooser__sample-code-notice {
    border-radius: 8px;
    width: 70vw;
    max-width: 650px;
    margin-top: 1em;
    max-height: 25vh;
    overflow: auto;

    summary {
        text-align: center;
        margin-bottom: 1em;
    }

}

.colibrio-file-chooser__sample-publications {
    border-radius: 8px;
    width: 70vw;
    max-width: 650px;
    margin-top: 1em;
    font-size: 1.2em;

    label {
        font-weight: 600;
        display: block;
    }

    select {
        margin-top: 16px;
        margin-bottom: 16px;
        cursor: pointer;
        border: 1px solid black;
        padding: 16px;
        width: 70vw;
        max-width: 100%;
    }
}
