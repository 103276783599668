
/* Sofia Pro Black */
@font-face {
  src: url('assets/font/Sofia/SofiaPro-Regular.ttf'),
  url('assets/font/Sofia/SofiaPro-Regular.otf');
  font-family: Sofia Pro Black;
  font-weight: 900;
  font-style: normal;
}


/* Sofia Pro Black italic */
@font-face {
  src: url('assets/font/Sofia/Sofia-Pro-Black-Italic-Az.ttf'),
  url('assets/font/Sofia/Sofia-Pro-Black-Italic-Az.otf');
  font-family: Sofia Pro Black italic;
  font-weight: 900;
  font-style: italic;
}


/* Sofia Pro Bold */
@font-face {
  src: url('assets/font/Sofia/SofiaPro-Bold.ttf'),
  url('assets/font/Sofia/SofiaPro-Bold.otf');
  font-family: Sofia Pro Bold;
  font-weight: 700;
  font-style: normal;
}


/* Sofia Pro Bold italic */
@font-face {
  src: url('assets/font/Sofia/Sofia-Pro-Bold-Italic-Az.otf'),
  url('assets/font/Sofia/Sofia-Pro-Bold-Italic-Az.otf');
  font-family: Sofia Pro Bold italic;
  font-weight: 700;
  font-style: italic;
}


/* Sofia Pro Extra Light */
@font-face {
  src: url('assets/font/Sofia/SofiaPro-Light.ttf'),
  url('assets/font/Sofia/SofiaPro-Light.otf');
  font-family: Sofia Pro Extra Light;
  font-weight: 200;
  font-style: normal;
}


/* Sofia Pro Extra Light italic */
@font-face {
  src: url('assets/font/Sofia/Sofia-Pro-ExtraLight-Italic-Az.ttf'),
  url('assets/font/Sofia/Sofia-Pro-ExtraLight-Italic-Az.otf');
  font-family: Sofia Pro Extra Light italic;
  font-weight: 200;
  font-style: italic;
}


/* Sofia Pro Light */
@font-face {
  src: url('assets/font/Sofia/Sofia-Pro-Light-Az.ttf'),
  url('assets/font/Sofia/Sofia-Pro-Light-Az.otf');
  font-family: Sofia Pro Light;
  font-weight: 300;
  font-style: normal;
}


/* Sofia Pro Light italic */
@font-face {
  src: url('assets/font/Sofia/Sofia-Pro-Light-Italic-Az.ttf'),
  url('assets/font/Sofia/Sofia-Pro-Light-Italic-Az.otf');
  font-family: Sofia Pro Light italic;
  font-weight: 300;
  font-style: italic;
}


/* Sofia Pro Medium */
@font-face {
  src: url('assets/font/Sofia/SofiaPro-Medium.ttf'),
  url('assets/font/Sofia/SofiaPro-Medium.otf');
  font-family: Sofia Pro Medium;
  font-weight: 500;
  font-style: normal;
}


/* Sofia Pro Medium italic */
@font-face {
  src: url('assets/font/Sofia/Sofia-Pro-Medium-Italic-Az.ttf'),
  url('assets/font/Sofia/Sofia-Pro-Medium-Italic-Az.otf');
  font-family: Sofia Pro Medium italic;
  font-weight: 500;
  font-style: italic;
}


/* Sofia Pro Regular */
@font-face {
  src: url('assets/font/Sofia/SofiaPro-Regular.ttf'),
  url('assets/font/Sofia/SofiaPro-Regular.otf');
  font-family: Sofia Pro Regular;
  font-weight: 400;
  font-style: normal;
}


/* Sofia Pro Regular italic */
@font-face {
  src: url('assets/font/Sofia/Sofia-Pro-Regular-Italic-Az.ttf'),
  url('assets/font/Sofia/Sofia-Pro-Regular-Italic-Az.otf');
  font-family: Sofia Pro Regular italic;
  font-weight: 400;
  font-style: italic;
}


/* Sofia Pro Semi Bold */
@font-face {
  src: url('assets/font/Sofia/SofiaPro-Bold.ttf'),
  url('assets/font/Sofia/SofiaPro-Bold.otf');
  font-family: Sofia Pro Semi Bold;
  font-weight: 600;
  font-style: normal;
}


/* Sofia Pro Ultra Light */
@font-face {
  src: url('assets/font/Sofia/Sofia-Pro-UltraLight-Az.ttf'),
  url('assets/font/Sofia/Sofia-Pro-UltraLight-Az.otf');
  font-family: Sofia Pro Ultra Light;
  font-weight: 100;
  font-style: normal;
}


/* Sofia Pro Ultra Light italic */
@font-face {
  src: url('assets/font/Sofia/Sofia-Pro-UltraLight-Italic-Az.ttf'),
  url('assets/font/Sofia/Sofia-Pro-UltraLight-Italic-Az.otf');
  font-family: Sofia Pro Ultra Light italic;
  font-weight: 100;
  font-style: italic;
}


/* Sofia Pro Semi Bold italic */
@font-face {
  src: url('assets/font/Sofia/Sofia-Pro-Semi-Bold-Italic-Az.ttf'),
  url('assets/font/Sofia/Sofia-Pro-Semi-Bold-Italic-Az.otf');
  font-family: Sofia Pro Semi Bold italic;
  font-weight: 600;
  font-style: italic;
}

/* Social Gothic*/
@font-face {
  src: url('assets/font/font/SocialGothic.ttf'),
  url('assets/font/font/SocialGothic.eot'),
  url('assets/font/font/SocialGothic.woff'),
  url('assets/font/font/SocialGothic.woff2');
  font-family: Social Gothic;
  font-style: normal;
}

/* Social Gothic Medium */
@font-face {
  src: url('assets/font/font/SocialGothic-Medium.ttf'),
  url('assets/font/font/SocialGothic-Medium.eot'),
  url('assets/font/font/SocialGothic-Medium.woff'),
  url('assets/font/font/SocialGothic-Medium.woff2');
  font-family: Social Gothic Medium;
  font-style: normal;
}

/* Social Gothic Demibold */
@font-face {
  src: url('assets/font/font/SocialGothic-Demibold.ttf'),
  url('assets/font/font/SocialGothic-Demibold.eot'),
  url('assets/font/font/SocialGothic-Demibold.woff'),
  url('assets/font/font/SocialGothic-Demibold.woff2');
  font-family: Social Gothic Demibold;
  font-weight: 900;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

.colibrio-reader__content--dark {
  background-image: url('assets/background-image.png');
  background-position: bottom;
  background-size: contain;
}

.pdf-bg-image {
  background-image: url('assets/comic-book-page.png') !important;
  background-size: auto !important;
  box-shadow: inset 0px 6px 8px -10px #ccc, inset 0px -175px 70px -32px #191919;
  background-position: right 125px;
  background-repeat: no-repeat;
}
.theme--dark.application {
  background-color: #000;
}
body {
  overflow-x: hidden;
  background-color: #000;
  transition: background-color 1s ease;
}
body.hideScroll {
  overflow: hidden;
}

/* navbar style */

.navbar{
  position: sticky;
  top: 0px;
  z-index: 11111112;
  height: 80px;
  padding-bottom: 0px;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover,.navbar-dark .navbar-nav .nav-link{
  color: #fff;
  font-family: Sofia Pro Regular;
}

.nav-item a, .menu-text, .nav-link{
  font-size: 18px;
}

.bg-black{
  background-color: #000;
}

.buy-now-btn{
  background: #D75828;
  border: 1px solid #D75828;
  border-radius: 30px;
  padding: 6px 60px;
  font-size: 18px;
  color: #fff;
  font-family: Sofia Pro medium;
}

.buy-now-btn:hover{
  background: transparent;
  border: 1px solid #D75828;
  color: #D75828;
}

.menu-toggler-container{
  cursor: pointer;
}

.menu-toggler{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-toggle {
  background: #D75828;
  height: 2px;
  width: 18px;
  position: relative;
  float: right;
  transition: all 300ms;
  margin-left: 10px;
  z-index: 1111;
}
.menu-toggle::before {
  content: "";
  position: absolute;
  border: 0px solid transparent;
  border-top: 2px solid #D75828;
  border-right: 30px solid #D75828;
  bottom: 6px;
  left: 0px;
  transition: all 300ms;
}

.menu-toggle::after {
  content: "";
  position: absolute;
  border: 0px solid transparent;
  border-top: 2px solid #D75828;
  border-right: 24px solid #D75828;
  top: 6px;
  left: 0px;
  transition: all 300ms;
}

/* .menu-toggler-container:hover .menu-toggle:after {
  border-right: 30px solid #D75828 !important;
}

.menu-toggler-container:hover .menu-toggle {
  width: 30px;
} */

.close-btn-transform{
  z-index: 1111;
}

.close-btn-transform .menu-toggle {
  background: transparent;
}

.close-btn-transform .menu-toggle::before {
  content: "";
  position: absolute;
  border-top: 2px solid #fff;
  border-right: 30px solid #fff;
  bottom: 0px;
  left: 0px;
  transform: rotate(45deg);
}

.close-btn-transform .menu-toggle::after {
  content: "";
  position: absolute;
  border-top: 2px solid #fff;
  border-right: 30px solid #fff;
  top: 0px;
  left: 0px;
  transform: rotate(-45deg);
}

.btn-link {
  color: #ffffff !important;
}

.manu-bar{
  background-color: #D75828;
  height: 100vh;
  background-image: url(assets/menu-bg.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom right;
  transform: translateY(100%);
  transition: all 200ms;
  color: #fff;
  /* background-position: 0% 109%; */
}

.side-menu-social-connect {
  bottom: 15px;
  position: absolute;
  width: 100%;
}

.side-menu-social-connect a{
  color: #fff;
  font-family: Sofia Pro light;
  width: 100%;
}

.mobile-menu-expanded.show .manu-bar{
  transform: translateY(0%);
}

.side-menu-item{
  transition: all 700ms;
  transform: translateY(100%);
}

.mobile-menu-expanded.show .side-menu-item{
  transform: translateY(0%);
}

.mobile-menu-expanded {
  /* display: none; */
  background-color: rgba(0, 0, 0, 0);
  transition: all 300ms;
  color: #fff;
  position: fixed;
  top: 0px;
  left: 100%;
  opacity: 0;
  max-width: 100vw;
  min-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  transform: translateY(100%);
  transition: left 0ms 500ms, background 200ms, transform 100ms 200ms, opacity 0ms 500ms;
  /* transition: all 300ms; */
}

.mobile-menu-expanded.show {
  background-color: rgba(0, 0, 0, 60%);
  z-index: 1;
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 0ms;
  left: 0%;
  transition: left 0ms, opacity 0ms, background 300ms 100ms, transform 100ms;
}

.side-menu-item{
  font-family: Social Gothic Medium;
  font-size: 50px;
}


.side-menu-item a{
  transition: all 300ms;
  position: relative;
  overflow: hidden;
}

.side-menu-item a::before{
  content: '';
  position: absolute;
  border: 2px solid transparent;
  border-color: transparent;
  border-width: 2px 0px 3px 0px;
  border-radius: 6px 0px 0px 6px;
  width: 95%;
  transition: all 500ms;
}

.side-menu-item a:hover::before{
  border-color: transparent #000 transparent #000;
  border-width: 2px 0px 3px 400px;
}

.side-menu-item .no{
  display: inline-block;
  margin-right: 10px;
  font-size: 20px;
  margin-bottom: 20px;
  opacity: 30%;
}

/* .close-btn-transform .menu-text{
  display: none !important;
} */

.nav-item{
  color: #fff;
  font-family: Sofia Pro Regular;
  margin-right: 20px;
}

@media screen and (max-width: 767px) {
  .navbar {
    min-height: 70px;
  }
  .navbar-nav .dropdown, .buy-now-btn{
    display: none;
  }
  .navbar-nav {
    flex-direction: inherit;
  }
  .mobile-menu-expanded.show {
    transform: translate(-1rem, -50px) !important;
    position: sticky;
  }
  .side-menu-item {
    font-family: Social Gothic Medium;
    font-size: 45px;
  }
  .side-menu-item .no{
    font-size: 24px;
  }
  .side-menu-social-connect a {
    font-size: 22px;
  }
  .manu-bar {
    max-width: 100vw;
  }
}
@media screen and (min-width: 1600px) {
  .side-menu-item{
    font-size: 60px;
  }

  .side-menu-item .no{
    font-size: 30px;
  }

  .nav-item a, .menu-text, .nav-link{
    font-size: 20px;
  }
}

.gothic{
  font-family: Social Gothic !important;
}

.gothicMedium{
  font-family: Social Gothic Medium !important;
}

.gothicDemibold{
  font-family: Social Gothic Demibold !important;
}

body{
  font-family: Sofia Pro Light;
}

nav{
  font-family: Sofia Pro bold;
}
.navbar-nav{
  flex-direction: column;
}

.btn{
  transition: all 600ms;
}

.rounded-btn{
  border-radius: 50px;
}

.black-outline-btn{
  background: transparent;
  border: 1px solid #000;
  color: #000;
}

.black-outline-btn:hover{
  background: #000;
  border: 1px solid #000;
  color: #fff;
}

.white-outline-btn{
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.white-outline-btn:hover{
  background: #fff;
  color: #000;
  border: 1px solid #fff;
}

.orange-outline-btn{
  background: transparent;
  color: #D75828;
  border: 1px solid #D75828;
}

.orange-outline-btn:hover{
  background: #D75828;
  color: #fff;
  border: 1px solid #D75828;
}

.orange-btn{
  border-radius: 50px;
  background: #D75828;
  color: #fff;
  border: 1px solid #D75828;
}

.orange-btn:hover{
  background: transparent;
  color: #D75828;
  border: 1px solid #D75828;
}
.color-black {
  background-color: #000;
}
.color-white {
  background-color: #fff;
}
.color-mix {
  background-image: linear-gradient(to bottom, #fff 50%, #000 50%);
}

@media screen and (max-width: 767px){
  /* @media (min-width: 1000px){
    .container {
        max-width: 85vw;
    }
  }
  @media (min-width: 1000px){
      .container {
          max-width: 85vw;
      }
  }
  @media (min-width: 1400px){
      .container {
          max-width: 80vw;
      }
  }
  @media (min-width: 1700px){
      .container {
          max-width: 80vw;
      }
  }
  @media (min-width: 1900px){
      .container {
          max-width: 80vw;
      }
  */
}

/*Pointer JS*/
#pointer-dot {
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border: 2.5px solid #ed4e31;
  position: absolute;
  border-radius: 4px;
  z-index: 99999999 !important;
  pointer-events: none;
  transition: border-color 0.5s;
}

#pointer-ring {
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  padding: 15px;
  border: 2px solid #ed4e31;
  position: absolute;
  border-radius: 100px;
  z-index: 99999999999 !important;
  pointer-events: none;
}
