

.v-slider__track, .v-slider__track-fill, .v-slider__track__container {
  height: 5px !important;
}
$ignore-aspect-ratio-at-width: 450px;

.dialog {
  max-width: 80%;

  @media (max-width: $ignore-aspect-ratio-at-width) {
    max-width: 100%;
  }
}

.colibrio-publication-view__media-player__rate-dialog__header, .colibrio-publication-view__media-player__volume-dialog__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.colibrio-publication-view {
  overflow: hidden;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.colibrio-publication-view__reader {
  align-items: center;

  .v-btn {
    z-index: 1;
  }

  @media (max-width: 700px) {
    .colibrio-publication-view__container-wrapper__nav-button {
      display: none;
    }
  }
  @media (max-height: 700px) {
    .colibrio-publication-view__container-wrapper__nav-button {
      display: none;
    }
  }
}

.colibrio-publication-view__loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;

  &.colibrio-publication-view__loader--visible {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.colibrio-publication-view__container-wrapper {
  position: relative;
  flex-grow: 1;
  align-self: stretch;
}

.colibrio-publication-view__container {
  position: absolute;
  top: 16px;
  left: 16px;
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $ignore-aspect-ratio-at-width) {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.colibrio-publication-view__container:focus {
  outline: none;
}

.colibrio-publication-view__navigation {
  width: 100%;
  display: flex;
}

.colibrio-publication-view__page-number {
  align-items: center;
  flex-grow: 0;
  justify-content: center;
  min-height: 40px;
  max-height: 40px;
}

.colibrio-publication-view__timeline {
  align-items: center;
  min-width: 32px;
  cursor: pointer;
  margin-top: 0;
}

.colibrio-publication-view__container-wrapper__nav-button {
  z-index: 2;
  cursor: pointer;
}

.colibrio-publication-view__popup-container {
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .colibrio-paged-view-vertical-scroll {

  }
}

@media (max-width: $ignore-aspect-ratio-at-width) {
  .colibrio-publication-view__container-wrapper__nav-button {
    display: none;
  }
}

.colibrio-publication-view__zoomed-image-container {
  flex-grow: 1;
}

.colibrio-publication-view__image-zoom-dialog {
  // The following props cause a lot of lag when enabled! there is no need for them either since the dialog is in fullscreen
  box-shadow: none !important;
  border-radius: 0 !important;

  max-width: 100%;
}

.colibrio-publication-view__page-loader {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.colibrio-publication-view__nav {
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
}

.colibrio-publication-view__media-player__container {
  display: block;
}

.colibrio-publication-view__media-player__volume-dialog {

  &.v-card {
    padding: 16px;
  }

  label {
    display: block;
    font-size: 1.2em;
    font-weight: 500;
  }

  .v-menu__activator {
    height: auto;
  }
}

.colibrio-publication-view__media-player__volume-dialog__ctrl {
  min-width: 250px;
  padding-top: 24px;
  padding-bottom: 40px;
  padding-left: 1em;
  padding-right: 0;
  font-size: 1.2em;

  option {
    font-size: 1.2em;
    padding-left: 1em;
  }
}

.colibrio-publication-view__media-player__rate-dialog {

  &.v-card {
    padding: 16px;
  }

  label {
    display: block;
    font-size: 1.2em;
    font-weight: 500;
  }

  .v-menu__activator {
    height: auto;
  }
}

.colibrio-publication-view__media-player__rate-dialog__ctrl {
  min-width: 250px;
  padding-top: 24px;
  padding-bottom: 40px;
  padding-left: 1em;
  padding-right: 0;
  font-size: 1.2em;

  option {
    font-size: 1.2em;
  }

  .v-input__icon--append {
    transform: scale(-1, 1) rotate(90deg);
  }

  .v-input__icon--prepend {
    transform: scale(-1) rotate(-90deg);
  }
}

.colibrio-publication-view__media-player__rate-button-slow {
  transform: scale(-1) rotate(-90deg);
}

.colibrio-publication-view__media-player__skip-escape-button {
  transform: scale(1, -1);
}

.colibrio-publication-view__media-player__loading-spinner {
  position: absolute;
  border: 2px dashed;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: colibrio-publication-view__media-player__loading-spinner__roll 3s infinite,
  colibrio-publication-view__media-player__loading-spinner__fade-in 1s 200ms forwards;
}

@keyframes colibrio-publication-view__media-player__loading-spinner__roll {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes colibrio-publication-view__media-player__loading-spinner__fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.colibrio-publication-view-toggle-button--active {
  background-color: #61004c;
}

.colibrio-publication-view-toggle-button--active .v-icon {
  color: #fff !important;
}

.colibrio-paged-view-flip-book__container.colibrio-paged-view-flip-book__left-spread:empty, .colibrio-paged-view-flip-book__container.colibrio-paged-view-flip-book__left-right:empty {
  background-color: transparent !important;
}

.colibrio-publication-view__notification {
  position: absolute;
  bottom: -1000px
}

.colibrio-publication-view__navigation-container {
  display: flex;
  justify-content: space-around;
}

.colibrio-publication-view__page-number {
  text-align: center;
}

.colibrio-paged-view-stack__container:focus-within, .colibrio-paged-view-flip-book:focus-within, .colibrio-paged-view-vertical-scroll:focus-within {
  outline: 2px solid rgba(207, 114, 178, 0.19);
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-width: 70vw;

  @media (max-width: $ignore-aspect-ratio-at-width) {
    max-width: 100%;
  }

}

.colibrio-publication-view__landmarks-dialog {

  padding: 16px;

  .colibrio-publication-view__popup-container {
    align-items: flex-start;
    justify-content: left;

    ul {
      margin-bottom: 1em;
      margin-top: 1em;
      margin-right: 2em;
      padding: 0;
    }

    li {
      list-style-type: none;
      font-size: 1.2em;
      font-weight: bold;
    }

    li > ul > li {
      list-style-type: none;
      font-size: 1em;
      font-weight: normal;
      margin-bottom: 20px;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}

